import React, { FC, useCallback, useEffect, useRef } from "react";
import ace from "brace";
import Ajv from "ajv";
import JSONEditor, { JSONEditorOptions } from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import "brace/mode/json";
import "brace/theme/monokai";

import schema from "./schema.json";
import { debounce } from "lodash";

type Props = {
  value: object;
  onChange: (value: object) => void;
};

export const WidgetJsonEditor: FC<Props> = ({ value = {}, onChange }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const editor = useRef<JSONEditor | null>(null);

  const handleChange = useCallback(
    debounce((textJson: string) => {
      try {
        const json = JSON.parse(textJson);
        const ajv = new Ajv({ allErrors: true, verbose: true });

        // Fire only for valid config
        if (ajv.validate(schema, json)) {
          onChange && onChange(json);
        }
      } catch {}
    }, 500),
    [onChange],
  );

  useEffect(() => editor.current?.destroy(), []);

  useEffect(() => {
    if (containerRef.current && !editor.current) {
      editor.current = new JSONEditor(
        containerRef.current,
        {
          schema,
          schemaRefs: schema.definitions,
          ace: ace as unknown as JSONEditorOptions["ace"],
          allowSchemaSuggestions: true,
          theme: "ace/theme/monokai",
          mode: "code",
          onChangeText: handleChange,
          colorPicker: true,
        },
        value,
      );
    }
  }, [containerRef, value, handleChange]);

  return <div ref={containerRef} />;
};
