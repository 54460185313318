import { translation } from "@lang/translation";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilledButton, PageHeader } from "@technis/ui";
import PlayIcon from "../../components/svg/play.svg";
import { WidgetJsonEditor } from "./WidgetJsonEditor";
import classNames from "classnames";
import { AnyObject } from "yup/lib/types";
import { DashboardConfigurationJSON } from "@technis/shared";
import { AuthService } from "@services/authService";
import { UserService } from "@services/userService";

const DEFAULT_CONFIG = {
  dashboard: {
    name: "Test dashboard",
    pages: [
      {
        name: "Page",
        icon: "",
        globalFilters: [],
        widgets: [],
      },
    ],
  },
};

const STORAGE_KEY = "widgetBuilder";

const getCachedData = () => {
  const cache = sessionStorage.getItem(STORAGE_KEY);

  if (!cache) {
    return DEFAULT_CONFIG;
  }

  try {
    return JSON.parse(cache);
  } catch {
    return DEFAULT_CONFIG;
  }
};

const getWidgetBuilderUrl = () => {
  const url = new URL(process.env.DASHBOARD_VISION_URL);
  url.pathname = "widget-builder";

  return url.href;
};

export const WidgetBuilder = () => {
  const { t } = useTranslation();
  const [config, setWidgetConfig] = useState<Partial<DashboardConfigurationJSON>>(getCachedData());
  const [dashboardUserEmail, setDashboardUserEmail] = useState<string>("");
  const [dashboardUserToken, setDashboardUserToken] = useState<string>("");
  const [hasInitSent, setHasInitSent] = useState<Partial<boolean>>(false);
  const [shouldShowPreview, setShouldShowPreview] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const widgetBuilderUrl = useMemo(getWidgetBuilderUrl, []);

  const checkDashboardUserEmailChange = async () => {
    const configEmail = (config as Partial<DashboardConfigurationJSON>)?.dashboard?.userEmail;

    if (!!configEmail && configEmail !== dashboardUserEmail) {
      setDashboardUserEmail(configEmail);

      const users = await UserService.search(configEmail);

      const user = users.find(({ email }) => email === configEmail.toLowerCase().trim());

      if (user) {
        const {
          data: { getViewAsUserToken },
        } = await AuthService.getViewAsUserToken(user.id);

        setDashboardUserToken(getViewAsUserToken);
      }
    }
  };

  useEffect(() => {
    checkDashboardUserEmailChange();
  }, [config]);

  useEffect(() => {
    dashboardUserToken && sendData({ token: dashboardUserToken });
  }, [dashboardUserToken]);

  const onChange = useCallback(
    (value: AnyObject) => {
      setWidgetConfig(value);
    },
    [setWidgetConfig],
  );

  const sendData = (data: AnyObject) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage({ widgetBuilder: { ...data } }, process.env.DASHBOARD_VISION_URL);
    }
  };

  const handleOnRun = () => {
    setShouldShowPreview(true);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify({ ...config }));
    sendData({ config });
  };

  const onPageLoaded = async () => {
    // keep possibility to send data along with init action
    sendData({
      init: true,
    });
    dashboardUserToken && sendData({ token: dashboardUserToken });
    setHasInitSent(true);
  };

  return (
    <div className="widget-builder">
      <header>
        <PageHeader title={t(translation.widgetBuilder.title)} className="title" />
        <div>
          <FilledButton onClick={handleOnRun} disabled={!hasInitSent}>
            <span className="button-content">
              <img src={PlayIcon} />
              {t(translation.widgetBuilder.run)}
            </span>
          </FilledButton>
        </div>
      </header>
      <main className="container">
        <div className="editor">
          <WidgetJsonEditor value={config} onChange={onChange} />
        </div>
        <div className={classNames("preview", { hidden: !shouldShowPreview })}>
          <iframe src={widgetBuilderUrl} ref={iframeRef} onLoad={onPageLoaded} onError={console.error} />
        </div>
      </main>
    </div>
  );
};
